<template>
    <div class="accountManage">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="accountManage--breadcrumb" >
        <el-breadcrumb-item :to="{ path: '/home' }">
          <font-awesome-icon icon="home" style="margin-right:10px;"/>首頁
        </el-breadcrumb-item  >
            
        <el-breadcrumb-item>
          <font-awesome-icon icon="search-dollar" style="margin-right:10px;"/>帳號管理
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="accountManage__listBox">
        <div class="accountManage__listBox--add" >
          <el-button type="danger" @click="setAccountFunc('add')"><font-awesome-icon icon="plus"  style="margin-right:5px;" />新增使用者</el-button>
          <!-- <span @click="setAccountFunc('add')">
            <i class="el-icon-plus"></i>
            <a>新增</a>
          </span> -->
        </div>      
        <div class="accountManage__listBox--Content">
          <el-table :data="tableData" ref="multipleTable" style="width: 100%"  empty-text="暫無數據">
            <el-table-column type="index" label="序號" width="60" ></el-table-column>
            <el-table-column label="使用者名稱" prop="UserName" min-width="40%"></el-table-column>
            <el-table-column label="部門" prop="DeptName" min-width="40%"></el-table-column>
            <el-table-column label="使用者角色"  width="150">
              <template slot-scope="scope">
                {{ scope.row.Role=="admin" ? "管理者" : "使用者" }}
              </template>            
            </el-table-column>
            <el-table-column label="啟用時間" width="200">
              <template slot-scope="scope">
                {{ scope.row.CreateDt | moment("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <div class="accountManage__listBox--Content--userFunc" >
                  <el-tooltip effect="dark" content="刪除帳號" placement="left" style="color:#000;text-shadow:2px 2px 2px grey">
                    <el-button type="text" @click="setAccountFunc('del', scope.row)">
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </el-tooltip>     
                  <el-tooltip effect="dark" content="變更密碼" placement="top" style="color:#000;text-shadow:2px 2px 2px grey" >
                    <el-button @click.native.prevent="changePWDModal(scope.row)" style="background-color:transparent;border:0px;" >
                      <i class="el-icon-setting"></i>
                    </el-button>                 
                  </el-tooltip>
                  <el-tooltip effect="dark" content="編輯帳號" placement="right" style="color:#000;text-shadow:2px 2px 2px grey">
                    <el-button type="text" @click="setAccountFunc('edit', scope.row)">
                      <i class="el-icon-edit"></i>
                    </el-button>
                  </el-tooltip>                                  

                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination @currentChange="pageChange" :pageSize="Page_Size" :propsCurrentPage="Page_Num" :totalLists="totalLists"> </Pagination>          
        </div>
      </div>

    <!-- 新增或編輯帳號 -->
    <el-dialog :title="setAccountTitle" :visible.sync="setAccount" width="60%" center>
      <el-form :model="userQuery" :rules="rules_setAccount" ref="ruleForm_setAccount" label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="使用者名稱" prop="UserName">
              <el-input v-model="userQuery.UserName" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用者角色" prop="Role">
              <el-select class="w-full" v-model="userQuery.Role" placeholder="請選擇角色">
                <el-option label="管理者" value="admin" ></el-option>
                <el-option label="使用者" value="user" ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <el-form-item label="使用者單位" prop="DeptName">          
                <el-input v-model="userQuery.DeptName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用者聯絡資訊" prop="Contact">
              <el-input v-model="userQuery.Contact"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-divider style="margin: 10px 0px;" ></el-divider> -->
        <el-row>
          <el-col :span="24">
            <el-form-item label="使用者email" prop="Account" >
              <el-input v-model="userQuery.Account" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!(setAccountTitle=='編輯帳號')">
          <el-col :span="12">
            <el-form-item label="使用者密碼" prop="Password">
              <el-input v-model="userQuery.Password"  show-password ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="請再次輸入密碼" prop="CheckPass">
              <el-input v-model="userQuery.CheckPass" show-password ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="備註" prop="Memo">
          <el-input type="textarea" v-model="userQuery.Memo"  :autosize="{ minRows: 2, maxRows: 4}" ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addUser()"><font-awesome-icon icon="save"  style="margin-right:5px;" />儲存</el-button>
        <el-button type="danger" @click="setAccount = false"><font-awesome-icon icon="times" style="margin-right:5px;" />取消</el-button>
      </span>
    </el-dialog>
    
    <!-- 變更密碼 -->
    <el-dialog title="變更密碼" :visible.sync="changePWD_func" width="60%" center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px"  >
        <el-form-item label="請輸入舊密碼" prop="oldPWD">
          <el-input v-model="ruleForm.oldPWD" show-password></el-input>
        </el-form-item>

        <el-form-item label="請輸入新密碼" prop="newPWD">
          <el-input v-model="ruleForm.newPWD" show-password></el-input>
        </el-form-item>

        <el-form-item label="請再次輸入新密碼" prop="checkPWD">
          <el-input v-model="ruleForm.checkPWD" show-password></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        
        <el-button type="primary" @click="changePWD()"><font-awesome-icon icon="save"  style="margin-right:5px;" />儲存</el-button>
        <el-button type="danger" @click="changePWD_func = false"><font-awesome-icon icon="times"  style="margin-right:5px;" />取消</el-button>
      </span>
    </el-dialog>      
        
    </div>
    
</template>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
import Pagination from "../components/Pagination.vue";

import API from "../api/api";
export default {
  // name : 'account-management',
  components: {Breadcrumb,Pagination},
  data() {
    var addValidatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("此為必填欄位"));
      } else if (value != this.userQuery.Password) {
        // console.log(value);
        // console.log(this.userQuery.Password);
        callback(new Error("兩次密碼輸入不一致"));
      } else {
        callback();
      }
    };
    var checkAccount = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (! value) {
        return callback( new Error('請輸入電子郵件' ))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback( new Error('請輸入正確的電子郵件格式' ))
        }
      }, 100 )
    };

    var changePWDValidatePass2 = (rule, value, callback) => {
      //console.log(this.ruleForm.newPWD, value);
      if (value === "") {
        callback(new Error("此為必填欄位"));
      } else if (value !== this.ruleForm.newPWD) {
        callback(new Error("兩次密碼輸入不一致"));
      } else {
        callback();
      }
    };    
    return {
      setAccount: false,
      setAccountTitle: "新增帳號",         
      setAccountMode:"add",   
      tableData: [],
      totalLists: 0,
      Page_Num: 1,
      Page_Size: 10,      
      /* 變更密碼 */
      changePWD_func: false,
      changePWDID: "",
      ruleForm: {
        oldPWD: "",
        newPWD: "",
        checkPWD: "",
      },
      rules: {
        oldPWD: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        newPWD: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        checkPWD: [
          {
            required: true,
            validator: changePWDValidatePass2,
            trigger: "blur",
          },
        ],
      },      

      /* 新增帳號 */
      userQuery: {
        //UserID: this.$store.state.userInfo.UserID,
        //UniComNm: this.$store.state.userInfo.UniComNm,
        UserID:"",
        ComName: "",
        UserName: "",
        Role: "",
        DeptName: "",
        Memo: "",
        Contact: "",
        Account: "",
        Password: "",
        CheckPass: "",
      },

      rules_setAccount: {
        UserName: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        Role: [{ required: true, message: "請選擇使用者角色", trigger: "change" }],
        DeptName: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        // contractInfo: [
        //   { required: true, message: "此為必填欄位", trigger: "blur" },
        // ],
        // Account: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        Account: [{  required: true, validator: checkAccount, trigger: "blur" }],
        Password: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
        CheckPass: [{ required: true, validator: addValidatePass2, trigger: "blur" }],
        // memo: [{ required: true, message: "此為必填欄位", trigger: "blur" }],
      },
    };
  },
  computed: {

  },
  methods: {
    showButton(row) {
      // if( this.UserRole=="manager" && row.Role=="user" )
      //   return false;
      // else 
      //   return true;
      if( row.UserName==this.$store.state.userInfo.UserName)
        return true;
      else
        return false;
    },    
    async changePWD() {
      if (!this.formValidate("ruleForm")) return;
      this.$confirm("確定要變更密碼嗎?", "變更提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {

          const pwChange = {
            CurrentPassword: this.ruleForm.oldPWD,
            Password: this.ruleForm.newPWD
          };
          // console.log(pwChange);

          this.$api.apimgrs.updatePassword(pwChange).then( res=>{
            // console.log(res);
            // 若成功會回...   result:"success"
            // 失敗..
//             {
//                "errorcode": "E201",
//                "message": "帳號密碼錯誤"
//            }

            (this.ruleForm = {
              oldPWD: "",
              newPWD: "",
              checkPWD: "",
            });
            //console.log(res);
            if(!! res.result)  {
              this.$notify({
                title: "成功",
                message: "修改成功",
                type: "success",
              });
            }
            else {
              this.$notify({
                type: "info",
                message: "舊密碼輸入錯誤",
                type: "warning",
              });
            }

            this.changePWD_func = false;
          }
          ).catch(err=>{
            console.log(err);
            this.$notify({
              type: "info",
              message: "已取消修改密碼",
              type: "warning",
            });
          });

          // const pwdQuery = {
          //   UserID: this.$store.state.userInfo.UserID,
          //   Password: this.ruleForm.newPWD
          // };
          //   this.$api.apimgrs.updateUserPassword(pwdQuery).then((res) => {
          //     (this.ruleForm = {
          //       oldPWD: "",
          //       newPWD: "",
          //       checkPWD: "",
          //     }),
          //       //console.log(res);
          //     this.$notify({
          //       title: "成功",
          //       message: "修改成功",
          //       type: "success",
          //     });
          //     this.changePWD_func = false;
          //   });
          // })
          // .catch(() => {
          //   this.$notify({
          //     type: "info",
          //     message: "已取消修改密碼",
          //     type: "warning",
          //   });

        })
    },    
    pageChange(val) {
      this.$store.dispatch("loadingHandler", true);
      this.Page_Num = val;
      this.getList();
      this.$store.dispatch("loadingHandler", false);
    },    
    formValidate(form) {
      let status = false;
      this.$refs[form].validate((valid) => {
        if (valid) {
          status = true;
        }
      });
      return status;
    },    


    // 新增或編輯使用者
    async addUser() {

      // alert('addUser');
      // if (!this.formValidate("ruleForm_setAccount")) {
      //   alert('retun');
      //   return;
      // }
      this.$store.dispatch("loadingHandler", true);

      
      if( this.setAccountMode=="add") {
        console.log("新增userQuery",this.userQuery);
        this.userQuery.LastMADt = Date.now();
        this.userQuery.CreateDt = Date.now();
        this.$api.apimgrs.addUser(this.userQuery).then((res)=>{
          console.log(res);
          this.$notify({
            title: "成功",
            message: "新增成功",
            type: "success",
          }); 
          this.getList(); 
          this.$store.dispatch("loadingHandler", false);
        }).catch(err=>{
            this.$notify.error({
            title: "錯誤",
            message: err,
            type: "Error"});
        });
        this.setAccount = false;
      }

      if( this.setAccountMode=="edit") {
        let req = this.userQuery;
        delete req.Account;
        delete req.Password;
        delete req.checkPass;
        delete req.Account;
        console.log("編輯userQuery",req);
        req.LastMADt = Date.now();
        this.$api.apimgrs.updateUser(req).then((res)=>{
          console.log(res);
          this.$notify({
            title: "成功",
            message: "編輯成功",
            type: "success",
          });
          this.getList(); 
          this.$store.dispatch("loadingHandler", false);                  
        }).catch(err=>{
            this.$notify.error({
            title: "錯誤",
            message: err,
            type: "Error"});
        });
        this.setAccount = false;
      }



    },    


    async delUser(userid) {
      this.$store.dispatch("loadingHandler", true);
      
      const delUserId = {
        UserID : userid
      };
      await this.$api.apimgrs.deleteUser(delUserId).then((res)=>{
        //console.log(res);
        this.$notify({
          title: "成功",
          message: "刪除成功",
          type: "success",
        });   
        this.getList();     
      });
      
      //await this.getList();

      this.$store.dispatch("loadingHandler", false);
    }, 

    async setAccountFunc(val, data) {
      
      console.log('op=', val)
      switch (val) {
        case "add":          
          this.setAccountMode = "add";
          this.setAccountTitle = "新增帳號";          

          let newuser = {
            //UserID: this.$store.state.userInfo.UserID,
            //UniComNm: this.$store.state.userInfo.UniComNm,
            UserID:"",
            ComName: "",
            UserName: "",
            Role: "",
            DeptName: "",
            Memo: "",
            Contact: "",
            Account: "",
            Password: "",
            CheckPass: "",
          };

          this.userQuery = newuser;
          this.setAccount = true;          
          
          break;
        case "edit":
          console.log(data);
          this.setAccountTitle = "編輯帳號";
          this.userQuery = data;
          this.setAccountMode ="edit";
          this.setAccount = true;
          break;
        case "del":
          console.log(data);
          this.$confirm("確定要刪除『" + data.UserName + "』嗎？", "提示", {
            confirmButtonText: "確定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
              // console.log("data",data);
              this.delUser(data.UserID);
              
            })
          .catch(() => {
              this.$notify({
                title: "失敗",
                message: "刪除失敗",
                type: "error",
              });  

          });          
          break;
      }
      
      
      
    },  
    changePWDModal(item) {
      //console.log(item);
      this.changePWDID = item.UserID;
      this.changePWD_func = true;
    },    
    async getList() {
      this.$store.dispatch("loadingHandler", true);
      this.tableData = [];
      let req = {Page_Size: this.Page_Size, Page_Num: this.Page_Num};     
      
      await this.$api.apimgrs.getAllUser(req).then((res)=>{
        //console.log("getallUser",res);
        // res.data.forEach(x=>{    
        //   if(this.$store.state.userInfo.Role=="manager") {
        //     this.tableData.push(x);
        //   }
        //   else {
        //     if( x.UserName==this.$store.state.userInfo.UserName)
        //       this.tableData.push(x);
        //   }
        // });
        
        this.tableData = res.data;
        this.totalLists = res.total;
        //console.log(this.tableData);        
      });
      
      this.$store.dispatch("loadingHandler", false);
    },
  },
  async mounted() {
    this.$store.dispatch("loadingHandler", true);
    this.getList();
    //this.$store.loading = true;
    let userID = window.localStorage.getItem("userID")
    this.$store.dispatch("loadingHandler", false);
  },

};
</script>
<style lang="scss" scoped>

.accountManage {
  width: 100%;
  height: 100vh;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%)
    center center / 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &--breadcrumb {
    padding: 20px 40px;
  }

  &__title {
    width: 100%;
    padding-top: 20px;
    text-align: center;

    strong {
      color: #191972;
      letter-spacing: 2px;
      font-size: 1.1rem;
    }
  }

  &__listBox {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--add {
      display: flex;
      flex-direction: row;
      width: 90%;
      justify-content: flex-end;
      margin-bottom: 20px;
    }

    &--Content {
      width: 90%;

      &--add {
        width: 100%;
        //padding: 0 30px;
        box-sizing: border-box;
        text-align: right;

        span {
          transition: 0.6s;
          cursor: pointer;
          i,
          a {
            font-size: 18px;
            font-weight: bold;
            color: #191972;
          }
          i {
            padding-right: 4px;
          }

          &:hover {
            letter-spacing: 2px;
          }
        }
      }

      &--userFunc {
        display: flex;
        flex-direction: row;
      }
    }
  }
}
</style>
